<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування HomePage" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Breadcrumb Label *"
                  v-model="breadcrumbLabel"
                  @input="delete errors.breadcrumbLabel"
                  :error="!!errors.breadcrumbLabel"
                  :error-messages="errors.breadcrumbLabel"
                />

                <va-card class="mb-3" title="Slides">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-slider-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-slider' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="slide in slides"
                      :key="slide.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card>
                        <div class="mb-1">{{ slide.label }} </div>
                        <div>{{ slide.description }}</div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-home-slider-edit', params: { id: slide.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>

                </va-card>

                <va-input
                  label="About Company Title *"
                  v-model="aboutCompanyTitle"
                  @input="delete errors.aboutCompanyTitle"
                  :error="!!errors.aboutCompanyTitle"
                  :error-messages="errors.aboutCompanyTitle"
                />
                <va-input
                  label="About Company Description *"
                  type="textarea"
                  v-model="aboutCompanyDescription"
                  @input="delete errors.aboutCompanyDescription"
                  :error="!!errors.aboutCompanyDescription"
                  :error-messages="errors.aboutCompanyDescription"
                />
                <va-card class="mb-3" title="About Company Image">
                  <div v-if="aboutCompanyImgExisting">
                    <a style="display: block;" target="_blank" :href="aboutCompanyImgExisting"><img style="max-width: 400px;" :src="aboutCompanyImgExisting"></a>
                    <div><va-button small color="danger" @click="aboutCompanyImgExisting = deleteImage(aboutCompanyImgId) ? '' : aboutCompanyImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutCompanyImage"
                    v-model="aboutCompanyImg"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('aboutCompanyImage', 'aboutCompanyImage', 'HomePage[aboutCompanyImage]', aboutCompanyImg[0])">Завантажити</va-button>
                  <div v-if="errors.aboutCompanyImage" style="color: red;">{{ errors.aboutCompanyImage[0] }}</div>
                </va-card>
                <va-input
                  label="About Company Detail Button Text"
                  v-model="aboutCompanyDetailButtonText"
                  @input="delete errors.aboutCompanyDetailButtonText"
                  :error="!!errors.aboutCompanyDetailButtonText"
                  :error-messages="errors.aboutCompanyDetailButtonText"
                />
                <va-input
                  label="About Company Detail Button Link"
                  v-model="aboutCompanyDetailButtonLink"
                  @input="delete errors.aboutCompanyDetailButtonLink"
                  :error="!!errors.aboutCompanyDetailButtonLink"
                  :error-messages="errors.aboutCompanyDetailButtonLink"
                />
                <va-input
                  label="Video Block Label"
                  v-model="videoBlockLabel"
                  @input="delete errors.videoBlockLabel"
                  :error="!!errors.videoBlockLabel"
                  :error-messages="errors.videoBlockLabel"
                />
                <va-card class="mb-3" title="Video Block Image">
                  <div v-if="videoBlockImageExisting">
                    <a style="display: block;" target="_blank" :href="videoBlockImageExisting"><img style="max-width: 400px;" :src="videoBlockImageExisting"></a>
                    <div><va-button small color="danger" @click="videoBlockImageExisting = deleteImage(videoBlockImageId) ? '' : videoBlockImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.videoBlockImage"
                    v-model="videoBlockImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('videoBlockImage', 'videoBlockImage', 'HomePage[videoBlockImage]', videoBlockImage[0])">Завантажити</va-button>
                  <div v-if="errors.videoBlockImage" style="color: red;">{{ errors.videoBlockImage[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Video Block Video">
                  <div v-if="videoBlockVideoExisting">
                    <a class="link" style="display: block;" target="_blank" :href="videoBlockVideoExisting">{{ videoBlockVideoExisting }}</a>
                    <div><va-button small color="danger" @click="videoBlockVideoExisting = deleteImage(videoBlockVideoId) ? '' : videoBlockVideoExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити новий файл</span>
                  <va-file-upload
                    dropzone
                    v-model="videoBlockVideo"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('videoBlockVideo', 'videoBlockVideo', 'HomePage[videoBlockVideo]', videoBlockVideo[0])">Завантажити</va-button>
                  <div v-if="errors.videoBlockVideo" style="color: red;">{{ errors.videoBlockVideo[0] }}</div>
                </va-card>

                <va-input
                  label="Our Services Title *"
                  v-model="ourServicesTitle"
                  @input="delete errors.ourServicesTitle"
                  :error="!!errors.ourServicesTitle"
                  :error-messages="errors.ourServicesTitle"
                />
                <va-input
                  label="Our Services Button Text *"
                  v-model="ourServicesButtonText"
                  @input="delete errors.ourServicesButtonText"
                  :error="!!errors.ourServicesButtonText"
                  :error-messages="errors.ourServicesButtonText"
                />

                <va-card class="mb-3" title="Services">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-service-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-service' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="service in services"
                      :key="service.id"
                      stripe="success"
                      class="flex xs4 mb-2"
                    >
                      <va-card>
                        <div class="mb-1">{{ service.label }} </div>
                        <div>{{ service.description }}</div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-service-edit', params: { id: service.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>

                <va-input
                  label="Our Pride Title *"
                  v-model="ourPrideTitle"
                  @input="delete errors.ourPrideTitle"
                  :error="!!errors.ourPrideTitle"
                  :error-messages="errors.ourPrideTitle"
                />

                <va-card class="mb-3" title="Pride Blocks">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-pride-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-pride' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="pride in prideBlocks"
                      :key="pride.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card>
                        <div class="mb-1">{{ pride.label }} </div>
                        <div>{{ pride.content }}</div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-home-pride-edit', params: { id: pride.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>

                <va-input
                  label="Social Responsibility Title"
                  v-model="socialResponsibilityTitle"
                  @input="delete errors.socialResponsibilityTitle"
                  :error="!!errors.socialResponsibilityTitle"
                  :error-messages="errors.socialResponsibilityTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>Social Responsibility Description</span>
                  <ckeditor v-model="socialResponsibilityDescription" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.socialResponsibilityDescription" style="color: red;">{{ errors.socialResponsibilityDescription[0] }}</div>
                </div>
                <va-input
                  label="Social Responsibility Show More Button Text"
                  v-model="socialResponsibilityShowMore"
                  @input="delete errors.socialResponsibilityShowMoreButtonText"
                  :error="!!errors.socialResponsibilityShowMoreButtonText"
                  :error-messages="errors.socialResponsibilityShowMoreButtonText"
                />
                <va-input
                  label="Social Responsibility Hide Button Text"
                  v-model="socialResponsibilityHideButton"
                  @input="delete errors.socialResponsibilityHideButtonText"
                  :error="!!errors.socialResponsibilityHideButtonText"
                  :error-messages="errors.socialResponsibilityHideButtonText"
                />
                <va-input
                  label="Partners Title *"
                  v-model="partnersTitle"
                  @input="delete errors.partnersTitle"
                  :error="!!errors.partnersTitle"
                  :error-messages="errors.partnersTitle"
                />

                <va-card class="mb-3" title="Partners">
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-partners-edit' }"
                  >
                    Додати
                  </va-button>
                  <va-button
                    class="mb-3"
                    :to="{ name: '1lk-home-partners' }"
                  >
                    Всі
                  </va-button>
                  <div class="row">
                    <div
                      v-for="partner in partners"
                      :key="partner.id"
                      stripe="success"
                      class="flex xs4 mb-2">
                      <va-card
                      >
                        <img style="max-height: 30px;" :src="apiUrl + partner.image">
                        <div class="mb-1">{{ partner.label }} </div>
                        <va-button
                          small
                          color="info"
                          :to="{ name: '1lk-home-partners-edit', params: { id: partner.id } }"
                        >
                          Редагувати
                        </va-button>
                      </va-card>
                    </div>
                  </div>
                </va-card>

                <va-input
                  label="News Title *"
                  v-model="newsTitle"
                  @input="delete errors.newsTitle"
                  :error="!!errors.newsTitle"
                  :error-messages="errors.newsTitle"
                />
                <va-input
                  label="News View All Button Text *"
                  v-model="newsViewAll"
                  @input="delete errors.newsViewAllButtonText"
                  :error="!!errors.newsViewAllButtonText"
                  :error-messages="errors.newsViewAllButtonText"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                  @input="delete errors.metaTitle"
                  :error="!!errors.metaTitle"
                  :error-messages="errors.metaTitle"
                />
                <va-input
                  label="Meta Description"
                  v-model="metaDescription"
                  @input="delete errors.metaDescription"
                  :error="!!errors.metaDescription"
                  :error-messages="errors.metaDescription"
                />
                <va-input
                  label="OG Title"
                  v-model="OgTitle"
                  @input="delete errors.OgTitle"
                  :error="!!errors.OgTitle"
                  :error-messages="errors.OgTitle"
                />
                <va-input
                  label="OG Description"
                  v-model="OgDescription"
                  @input="delete errors.OgDescription"
                  :error="!!errors.OgDescription"
                  :error-messages="errors.OgDescription"
                />
                <va-card class="mb-3" title="OG Image">
                  <div v-if="OgImageExisting">
                    <a style="display: block;" target="_blank" :href="OgImageExisting"><img style="max-width: 400px;" :src="OgImageExisting"></a>
                    <div><va-button small color="danger" @click="OgImageExisting = deleteImage(OgImageId) ? '' : OgImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="OgImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', null, 'SeoEntity[image]', OgImage[0], 'common\\models\\SeoEntity')">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-input
                  label="seo text title"
                  v-model="seoTextTitle"
                  @input="delete errors.seoTextTitle"
                  :error="!!errors.seoTextTitle"
                  :error-messages="errors.seoTextTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>seo text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="seo text see more text"
                  v-model="seoTextSeeMore"
                  @input="delete errors.seoTextSeeMore"
                  :error="!!errors.seoTextSeeMore"
                  :error-messages="errors.seoTextSeeMore"
                />
                <va-input
                  label="Seo Text Hide Text"
                  v-model="seoTextHide"
                  @input="delete errors.seoTextHide"
                  :error="!!errors.seoTextHide"
                  :error-messages="errors.seoTextHide"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      breadcrumbLabel: '',
      slides: [],
      aboutCompanyTitle: '',
      aboutCompanyDescription: '',
      aboutCompanyImg: [],
      aboutCompanyImgId: '',
      aboutCompanyImgExisting: '',
      aboutCompanyDetailButtonText: '',
      aboutCompanyDetailButtonLink: '',
      videoBlockLabel: '',
      videoBlockImage: [],
      videoBlockImageId: '',
      videoBlockImageExisting: '',
      videoBlockVideo: [],
      videoBlockVideoId: '',
      videoBlockVideoExisting: '',
      ourServicesTitle: '',
      ourServicesButtonText: '',
      services: [],
      ourPrideTitle: '',
      prideBlocks: [],
      socialResponsibilityTitle: '',
      socialResponsibilityDescription: '',
      socialResponsibilityShowMore: '',
      socialResponsibilityHideButton: '',
      partnersTitle: '',
      partners: [],
      newsTitle: '',
      newsViewAll: '',

      seoId: null,
      metaTitle: '',
      metaDescription: '',
      OgTitle: '',
      OgDescription: '',
      OgImage: [],
      OgImageId: '',
      OgImageExisting: '',
      seoTextTitle: '',
      seoText: '',
      seoTextSeeMore: '',
      seoTextHide: '',

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_1LK_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      errors: [],

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    apiUrl () {
      return process.env.VUE_APP_1LK_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (attr, entityAttribute, param, file, model = 'common\\modules\\kvs\\models\\HomePage') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (entityAttribute === null) {
        formData.append('id', this.seoId ? this.seoId : '0')
      } else {
        formData.append('id', '0')
        formData.append('entity_attribute', entityAttribute)
      }

      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_1LK_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        breadcrumbLabel: this.breadcrumbLabel,
        aboutCompanyTitle: this.aboutCompanyTitle,
        aboutCompanyDescription: this.aboutCompanyDescription,
        aboutCompanyDetailButtonText: this.aboutCompanyDetailButtonText,
        aboutCompanyDetailButtonLink: this.aboutCompanyDetailButtonLink,
        videoBlockLabel: this.videoBlockLabel,
        ourServicesTitle: this.ourServicesTitle,
        ourServicesButtonText: this.ourServicesButtonText,
        ourPrideTitle: this.ourPrideTitle,
        socialResponsibilityTitle: this.socialResponsibilityTitle,
        socialResponsibilityDescription: this.socialResponsibilityDescription,
        socialResponsibilityShowMoreButtonText: this.socialResponsibilityShowMore,
        socialResponsibilityHideButtonText: this.socialResponsibilityHideButton,
        partnersTitle: this.partnersTitle,
        newsTitle: this.newsTitle,
        newsViewAllButtonText: this.newsViewAll,

        SeoEntity: {
          title: this.metaTitle,
          description: this.metaDescription,
          og_title: this.OgTitle,
          og_description: this.OgDescription,
          seo_text_title: this.seoTextTitle,
          seo_text: this.seoText,
          seo_text_see_more_text: this.seoTextSeeMore,
          seo_text_hide_text: this.seoTextHide,
        },
      }
      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/home-texts?lang=${this.locale}`, data)
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/home-texts?lang=${this.locale}`)
        .then(response => {
          this.breadcrumbLabel = response.data.breadcrumbLabel
          this.aboutCompanyTitle = response.data.aboutCompanyTitle
          this.aboutCompanyDescription = response.data.aboutCompanyDescription
          this.aboutCompanyDetailButtonText = response.data.aboutCompanyDetailButtonText
          this.aboutCompanyDetailButtonLink = response.data.aboutCompanyDetailButtonLink
          this.videoBlockLabel = response.data.videoBlockLabel
          this.ourServicesTitle = response.data.ourServicesTitle
          this.ourServicesButtonText = response.data.ourServicesButtonText
          this.ourPrideTitle = response.data.ourPrideTitle
          this.socialResponsibilityTitle = response.data.socialResponsibilityTitle
          this.socialResponsibilityDescription = response.data.socialResponsibilityDescription ? response.data.socialResponsibilityDescription : ''
          this.socialResponsibilityShowMore = response.data.socialResponsibilityShowMoreButtonText
          this.socialResponsibilityHideButton = response.data.socialResponsibilityHideButtonText
          this.partnersTitle = response.data.partnersTitle
          this.newsTitle = response.data.newsTitle
          this.newsViewAll = response.data.newsViewAllButtonText

          this.aboutCompanyImgExisting = response.data.aboutCompanyImage ? process.env.VUE_APP_1LK_ADMIN + response.data.aboutCompanyImage : null
          this.aboutCompanyImgId = response.data.aboutCompanyImageId
          this.videoBlockImageExisting = response.data.videoBlockImage ? process.env.VUE_APP_1LK_ADMIN + response.data.videoBlockImage : null
          this.videoBlockImageId = response.data.videoBlockImageId
          this.videoBlockVideoExisting = response.data.videoBlockVideo ? process.env.VUE_APP_1LK_ADMIN + response.data.videoBlockVideo : null
          this.videoBlockVideoId = response.data.videoBlockVideoId

          this.seoId = response.data.seo.id
          this.metaTitle = response.data.seo.title
          this.metaDescription = response.data.seo.description
          this.OgTitle = response.data.seo.og_title
          this.OgDescription = response.data.seo.og_description
          this.seoTextTitle = response.data.seo.seo_text_title
          this.seoText = response.data.seo.seo_text ? response.data.seo.seo_text : ''
          this.seoTextSeeMore = response.data.seo.seo_text_see_more_text
          this.seoTextHide = response.data.seo.seo_text_hide_text

          this.OgImageExisting = response.data.seo.og_image ? process.env.VUE_APP_1LK_ADMIN + response.data.seo.og_image : null
          this.OgImageId = response.data.seo.og_image_id

          this.slides = response.data.slides
          this.services = response.data.services
          this.prideBlocks = response.data.prides
          this.partners = response.data.partners
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
